import '../index.css'

import * as prismic from '@prismicio/client'
import cookie from 'js-cookie'

import { NavigationController } from './NavigationController'

import pageStyles from './Page.module.css'
import navigationStyles from './Navigation.module.css'
import anchorStyles from './Anchor.module.css'

import { getPreview } from './Preview'
import { repo_name } from '../../config.json'
import { AnchorController } from './AnchorController'
import { Lang } from '../../const'
import { EventEmitter } from './EventEmitter'

export interface Controller {
	resize?(): void

	show?(animate?: boolean): Promise<void>

	hide?(): Promise<void>

	update?(time: number): void

	load?(): Promise<void>

	scroll?(scrollY: number): void
}

export class App extends EventEmitter {
	private readonly parser: DOMParser = new DOMParser()
	private readonly previewRef: string | undefined
	private readonly hasPreview: boolean
	private readonly observer: ResizeObserver

	private controllers: Controller[] = []
	private needsScrollUpdate = false

	public readonly intersectionObserver: IntersectionObserver

	public bodyWidth = 0
	public bodyHeight = 0
	public windowWidth = 0
	public windowHeight = 0
	public scrollY = 0
	public isDesktop = false
	public isLandscape = false
	public lang: Lang = Lang.de
	private main: HTMLElement

	constructor() {
		super()
		this.update = this.update.bind(this)

		const mq = window.matchMedia('(min-width:1024px)')
		mq.addEventListener('change', () => (this.isDesktop = mq.matches))
		this.isDesktop = mq.matches

		this.scrollY = window.scrollY
		this.windowWidth = window.innerWidth
		this.windowHeight = window.innerHeight
		this.isLandscape = this.windowWidth > this.windowHeight
		this.lang = document.documentElement.lang as Lang

		this.main = document.querySelector(`.${pageStyles.Main}`) as HTMLElement

		this.intersectionObserver = new IntersectionObserver((entries) => this.emit('intersect', entries))

		const previewCookie = cookie.get(prismic.cookie.preview)
		this.previewRef = previewCookie
		this.hasPreview = previewCookie ? !!JSON.parse(previewCookie)[`${repo_name}.prismic.io`] : false

		this.observer = new ResizeObserver(this.onResize.bind(this))

		this.init()
	}

	async onFontLoad() {
		return new Promise<void>((resolve) => document.fonts.ready.then(() => resolve()))
	}

	async init(): Promise<void> {
		await Promise.all([this.onFontLoad()])

		if (this.hasPreview) {
			const html = await this.loadPage(window.location.pathname)
			if (html) this.updatePage(html)
		}

		requestAnimationFrame(this.update)
		window.addEventListener('scroll', this.onScroll.bind(this))

		this.setControllers()

		await Promise.all([this.loadControllers()])
		this.observer.observe(document.body)

		this.controllers.forEach((controller) => controller.show?.(true))
	}

	async loadPage(route?: string): Promise<string> {
		if (this.hasPreview && this.previewRef) {
			return await getPreview(this.previewRef, route)
		} else {
			const response = await fetch(`${route}index.html`)
			return await response.text()
		}
	}

	updatePage(html: string): void {
		const doc = this.parser.parseFromString(html, 'text/html')
		const mainNode = doc.querySelector(`.${pageStyles.Main}`) as HTMLElement

		document.title = doc.title
		document.documentElement.lang = doc.documentElement.lang

		window.scrollTo(0, 0)
		this.scrollY = 0
		this.main?.replaceWith(mainNode)
		this.main = document.querySelector(`.${pageStyles.Main}`) as HTMLElement
	}

	setControllers(): void {
		this.controllers = [
			...this.getNodes(navigationStyles.Main).map((node) => new NavigationController(node, this)),
			...this.getNodes(anchorStyles.Main).map((node) => new AnchorController(node))
		]
	}

	async loadControllers() {
		await Promise.all([this.controllers.map((controller) => controller.load?.())])
	}

	getNodes(className: string): HTMLElement[] {
		return Array.from(document.body?.querySelectorAll(`.${className}`) as NodeListOf<HTMLElement>)
	}

	onResize(): void {
		const { width, height } = this.main.getBoundingClientRect()

		// prevent resize events on scroll
		if (this.bodyWidth !== width || this.bodyHeight !== height) {
			this.windowWidth = window.innerWidth
			this.windowHeight = window.innerHeight
			this.bodyWidth = width
			this.bodyHeight = height
			this.isLandscape = this.windowWidth > this.windowHeight
			this.controllers.forEach((controller) => controller.resize?.())
		}
	}

	onScroll(): void {
		this.needsScrollUpdate = true
	}

	update(time: number): void {
		requestAnimationFrame(this.update)

		if (this.needsScrollUpdate) {
			this.needsScrollUpdate = false
			this.scrollY = window.scrollY
			this.controllers.forEach((controller) => controller.scroll?.(window.scrollY))
		}

		this.controllers.forEach((controller) => controller.update?.(time))
	}
}
