import styles from './Navigation.module.css'
import anchorStyles from './Anchor.module.css'
import { App, Controller } from './App'

type Background = 'light' | 'dark'

export class NavigationController implements Controller {
	private readonly burger: HTMLElement
	private readonly close: HTMLElement
	private readonly menu: HTMLElement
	private readonly items: HTMLAnchorElement[]
	private readonly anchors: HTMLElement[]
	private readonly sections: HTMLDivElement[]
	private menuOpen = false
	private anchorsTop: number[] = []
	private sectionsHeight: number[] = []
	private sectionsTop: number[] = []
	private active: null | string = null
	private background: Background = 'dark'
	private menuHeight: number = 0

	constructor(
		private readonly node: HTMLElement,
		private readonly app: App
	) {
		this.closeMenu = this.closeMenu.bind(this)
		this.openMenu = this.openMenu.bind(this)

		this.burger = node.querySelector(`.${styles.Burger}`) as HTMLElement
		this.close = node.querySelector(`.${styles.Close}`) as HTMLElement
		this.menu = node.querySelector(`.${styles.Menu}`) as HTMLElement
		this.items = [
			...Array.from(node.querySelectorAll(`.${styles.Anchor}`) as NodeListOf<HTMLAnchorElement>),
			...Array.from(node.querySelectorAll(`.${styles.Button}`) as NodeListOf<HTMLAnchorElement>)
		]

		this.anchors = Array.from(document.querySelectorAll(`.${anchorStyles.Main}`) as NodeListOf<HTMLElement>)
		this.sections = Array.from(document.querySelectorAll('section') as NodeListOf<HTMLDivElement>)

		this.items.forEach((item) => item.addEventListener('click', this.closeMenu))
		this.burger.addEventListener('click', this.openMenu)
		this.close.addEventListener('click', this.closeMenu)
		window.addEventListener('scroll', this.closeMenu)
	}

	async openMenu(): Promise<void> {
		if (this.menuOpen) return
		this.menuOpen = true
		this.node.classList.add(styles.Open)
		this.burger.classList.add(styles.Hidden)
		this.close.classList.add(styles.Visible)
	}

	async closeMenu(): Promise<void> {
		if (!this.menuOpen) return
		this.menuOpen = false
		this.node.classList.remove(styles.Open)
		this.burger.classList.remove(styles.Hidden)
		this.close.classList.remove(styles.Visible)
	}

	resize() {
		const { scrollY } = this.app
		this.menuHeight = this.menu.getBoundingClientRect().height
		this.anchorsTop = this.anchors.map((anchor) => anchor.getBoundingClientRect().top + scrollY)

		this.sections.forEach((section, index) => {
			const { top, height } = section.getBoundingClientRect()
			this.sectionsTop[index] = top + scrollY
			this.sectionsHeight[index] = height
		})

		this.updateNav()
	}

	scroll(scrollY: number) {
		this.updateNav()
	}

	updateNav() {
		const { windowHeight, scrollY } = this.app
		const offset = windowHeight * 0.25
		let index = null
		for (let i = this.anchorsTop.length - 1; i >= 0; i--) {
			const top = this.anchorsTop[i] - scrollY
			index = i
			if (top <= offset) {
				break
			}
		}

		if (index !== null) {
			const active = this.anchors[index]?.dataset.id || null
			if (this.active !== active) {
				this.active = active
				this.items.forEach((item) =>
					item.dataset.id === active
						? item.classList.add(styles.Active)
						: item.classList.remove(styles.Active)
				)
			}
		}

		for (let i = 0; i < this.sections.length; i++) {
			const top = this.sectionsTop[i] - scrollY
			const bottom = top + this.sectionsHeight[i]

			if (top <= this.menuHeight && bottom > this.menuHeight) {
				const background = this.sections[i].dataset.background as Background
				if (this.background !== background) {
					this.background = background
					this.background === 'light'
						? this.node.classList.add(styles.Light)
						: this.node.classList.remove(styles.Light)
				}
				break
			}
		}
	}
}
