import styles from './Timeline.module.css'
import { PageDocumentDataBodyTimelineSlice } from '../@types/types.generated'
import clsx from 'clsx'
import { asHTML } from '@prismicio/helpers'

export function Timeline(props: PageDocumentDataBodyTimelineSlice): string {
	const { items } = props
	const years = items
		.map((item) => parseInt(item.year as string, 10))
		.filter((year) => !isNaN(year))
		.flat()
		.sort()

	const first = (years.at(0) || 0.2) - 0.2
	const last = (years.at(-1) || 0) + 4
	const duration = last - first

	return /* HTML */ `<section class="${styles.Main}" data-background="light">
		<div class="${styles.Wrapper}">
			<div class="${styles.Line}"></div>
			<div class="${styles.Inner}">
				${items
					.map((item, index) => {
						if (isNaN(parseInt(item.year as string, 10))) {
							return ''
						}

						const nextItemOnSameSide = items[index + 2]
						const year = parseInt(item.year as string, 10)
						const nextYear = nextItemOnSameSide ? parseInt(nextItemOnSameSide.year as string, 10) : last
						const distance = ((year - first) / duration) * 100
						const nextDistance = ((nextYear - first) / duration) * 100

						const width = nextDistance - distance

						return /* HTML */ `<div
							class="${clsx(styles.Item, index % 2 === 0 && styles.Even)}"
							style="--distance: ${distance.toFixed(2)}%; --width: ${width.toFixed(2)}%"
						>
							<div class="${styles.Year}">${item.year}</div>
							<div class="${styles.Label}">${asHTML(item.label)}</div>
						</div>`
					})
					.join('')}
			</div>
		</div>
	</section>`
}
