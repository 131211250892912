import { AlternateLanguage } from '@prismicio/types'

import { domain, repo_name } from '../../config.json'
import font1 from '../assets/PPNeueMontreal-Medium.woff2'
import font2 from '../assets/libre-baskerville-v14-latin-regular.woff2'
import clsx from 'clsx'
import styles from './Html.module.css'
import { resolver } from '../lib/resolver'
import favicon from '../assets/favicon.png'
import ogImage from '../assets/OpenGraphImage.png'
import { Lang, ShortLang } from '../../const'

export type Props = {
	lang: Lang
	routes: string[]
	page_title?: string
	alternate_languages?: AlternateLanguage<string, Lang>[]
	children?: string
	fixed?: boolean
	dev?: boolean
}

export function Html(props: Props): string {
	const {
		lang,
		routes,
		page_title = 'Setaprint',
		alternate_languages = [],
		children = '',
		fixed = false,
		dev = false
	} = props
	//
	return /* HTML */ `
		<!doctype html>
		<html lang="${ShortLang[lang]}">
			<head>
				<meta charset="utf-8" />
				${alternate_languages
					.map(({ uid, type, lang }) =>
						uid && type && lang
							? /* HTML */ `<link
									rel="alternate"
									hreflang="${lang}"
									href="${domain}${resolver(uid, type, lang)}/"
							  />`
							: ''
					)
					.join('')}
				<title>${page_title}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta property="og:image" content="${domain}${ogImage}" />
				<link rel="shortcut icon" type="image/png" href="${favicon}" />
				<link rel="preload" href="${font1}" as="font" crossorigin="anonymous" />
				<link rel="preload" href="${font2}" as="font" crossorigin="anonymous" />
				${routes.map((route) => `<link rel="prefetch" href="${route}/index.html"/>`).join('')}
				<script>
					window.__ROUTES__ = ${JSON.stringify(routes)}
				</script>
				<script async defer src="/index.js"></script>
				<script async defer src="https://static.cdn.prismic.io/prismic.js?new=true&repo=${repo_name}"></script>
				<link rel="stylesheet" type="text/css" href="/index.css" />

				${dev
					? /* HTML */ `<script>
							new EventSource('/esbuild').addEventListener('change', () => location.reload())
					  </script>`
					: ''}
			</head>
			<body class="${clsx(styles.Body, fixed && styles.IsFixed)}">
				${children}
			</body>
		</html>
	`
}
