import styles from './Title.module.css'
import clsx from 'clsx'
import { KeyTextField, RichTextField } from '@prismicio/client'
import { asHTML } from '@prismicio/helpers'
import linkResolver from '../lib/linkResolver'

export const Title = (title: RichTextField, eyebrow: KeyTextField, negative = false, large = false): string => {
	return /* HTML */ `<div class="${clsx(styles.Main, negative && styles.Negative, large && styles.Large)}">
		<div class="${styles.Eyebrow}">
			<svg class="${styles.Icon}" viewBox="0 0 72 4" xmlns="http://www.w3.org/2000/svg">
				<circle cx="2" cy="2" r="2" />
				<path d="M2 2H72" />
			</svg>

			<div class="${styles.Label}">${eyebrow}</div>
		</div>

		<div class="${styles.Title}">${asHTML(title, linkResolver)}</div>
	</div>`
}
