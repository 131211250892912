import { Lang, ShortLang } from '../../const'

export const resolver = (uid: string, type: string, lang: Lang | string, anchor?: string): string => {
	let route = '/'

	if (type === 'page') {
		route = `/${ShortLang[lang as Lang]}${uid === 'index' ? '/' : `/${uid}/`}`
	}

	//static generation or existing route for preview
	if (typeof window === 'undefined' || window.__ROUTES__.includes(route)) {
		return `${route}${anchor ? `#${anchor}` : ''}`
	}

	//route unpublished documents to generic page
	return `/unpublished/`
}
