import styles from './Team.module.css'
import { PageDocumentDataBodyTeamSlice } from '../@types/types.generated'
import { asHTML } from '@prismicio/helpers'
import { Title } from './Title'
import { isFilled } from '@prismicio/client'
import linkResolver from '../lib/linkResolver'

const IMAGE_SIZES = [360, 480, 960, 1440]

function getSrcSet(url: string, sizes: number[]) {
	const { origin, pathname, searchParams } = new URL(url)

	searchParams.delete('w')
	searchParams.delete('h')

	const src = `${origin}${pathname}?${searchParams.toString()}`
	return sizes.map((size) => `${src}&w=${size} ${size}w`).join(', ')
}

export function Team(props: PageDocumentDataBodyTeamSlice): string {
	const {
		primary: { title, eyebrow },
		items
	} = props

	return /* HTML */ `<section class="${styles.Main}" data-background="light">
		<div class="${styles.Inner}">
			<div class="${styles.Title}">${Title(title, eyebrow)}</div>
			<div class="${styles.Grid}">
				${items
					.map((item) => {
						const {
							image,
							name,
							description,
							links,
							email,
							phone,
							image: { url, alt, dimensions }
						} = item

						const aspect = isFilled.image(image)
							? (dimensions?.width || 16) / (dimensions?.height || 9)
							: 16 / 9

						return /* HTML */ `<article class="${styles.Item}">
							<div class="${styles.Name}">${name}</div>
							<div class="${styles.Image}">
								<div class="${styles.Aspect}" style="--aspect:${aspect}">
									${isFilled.image(image)
										? `<img class="${styles.Img}" alt="${alt}" srcset="${
												url ? getSrcSet(url, IMAGE_SIZES) : ''
										  }" src="${url}" sizes=" (min-width: 1024px) 33vw, 100vw" loading="lazy"/>`
										: ''}
								</div>
							</div>
							${description
								? `<div class="${styles.Description}">${asHTML(description, linkResolver)}</div>`
								: ''}
							<div class="${styles.Contact}">
								${email ? `<a href="mailto:${email}">${email}</a>` : ''}
								${phone ? `<a href="tel:${phone}">${phone}</a>` : ''}
							</div>
							<div class="${styles.Links}">${asHTML(links, linkResolver)}</div>
						</article>`
					})
					.join('')}
			</div>
		</div>
	</section>`
}
