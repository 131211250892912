import styles from './Page.module.css'
import { FooterDocument, NavigationDocument, PageDocument } from '../@types/types.generated'
import { Lang } from '../../const'
import { Navigation } from './Navigation'
import { Text } from './Text'
import { Header } from './Header'
import { Timeline } from './Timeline'
import { Team } from './Team'
import { Contact } from './Contact'
import { Anchor } from './Anchor'
import { Footer } from './Footer'

type Props = {
	page: PageDocument<Lang>
	routes: string[]
	navigation?: NavigationDocument<Lang>
	footer?: FooterDocument<Lang>
}

export function Page({ page, navigation, footer }: Props): string {
	const { alternate_languages, lang, data: { body = [] } = {} } = page

	return /* HTML */ `
		<main class="${styles.Main}">
			<div class="${styles.Inner}">
				${body
					.map((slice, index) => {
						switch (slice.slice_type) {
							case 'anchor': {
								return Anchor(slice)
							}
							case 'text': {
								const previousSlice = body[index - 1]
								const noTopBorder =
									previousSlice?.slice_type === 'timeline' ||
									(previousSlice?.slice_type === 'text' &&
										previousSlice?.primary.background === 'dark-grey')

								return Text(slice, noTopBorder)
							}
							case 'header': {
								return Header(slice)
							}
							case 'timeline': {
								return Timeline(slice)
							}
							case 'team': {
								return Team(slice)
							}
							case 'contact': {
								return Contact(slice)
							}
						}
					})
					.join('')}
				${footer ? Footer(footer) : ''}
			</div>
			${navigation ? Navigation({ navigation, lang, alternate_languages }) : ''}
		</main>
	`
}
