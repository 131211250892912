import cookie from 'js-cookie'

import { preview_document_id_cookie, repo_name } from '../../config.json'
import { getData } from '../lib/primsic'
import { Page } from './Page'
import { PageDocument } from '../@types/types.generated'
import * as prismic from '@prismicio/client'
import { routeResolver } from '../lib/routeResolver'
import { Html } from './Html'
import { Lang, ShortLang } from '../../const'

const isPage = (doc: any): doc is PageDocument<Lang> => doc.type === 'page'

const endpoint = prismic.getRepositoryEndpoint(repo_name)
export const client = prismic.createClient(endpoint)

export async function getPreview(ref: string, route?: string): Promise<string> {
	const { allPages, allNavigations, allFooters } = await getData(client, ref)
	const allDocs = [...allPages, ...allNavigations]
	const routes = [...allPages.map((page) => `${routeResolver(page)}`), '/unpublished/']

	let doc

	if (route === '/unpublished/') {
		const id = cookie.get(preview_document_id_cookie)
		doc = allDocs.find((page) => page.id === id)
	} else {
		const routeArray = route ? route.split('/') : []
		const lang = routeArray[1] as ShortLang
		const uid = routeArray[2] || 'index'
		doc = allPages.find((page) => page.uid === uid && page.lang === Lang[lang])
	}

	if (doc && route) {
		if (isPage(doc)) {
			const page = doc
			const {
				lang,
				alternate_languages,
				data: { page_title }
			} = page

			const navigation = allNavigations.find((header) => header.lang === lang)
			const footer = allFooters.find((footer) => footer.lang === lang)

			return Html({
				lang,
				routes,
				alternate_languages,
				page_title: page_title || undefined,
				dev: false,
				children: Page({ page, routes, navigation, footer })
			})
		}
	}

	return ''
}
