import styles from './Header.module.css'
import { PageDocumentDataBodyHeaderSlice } from '../@types/types.generated'
import { Title } from './Title'

export function Header(props: PageDocumentDataBodyHeaderSlice): string {
	const {
		primary: { title, eyebrow }
	} = props

	return /* HTML */ `<section class="${styles.Main}" data-background="dark">
		<div class="${styles.Inner}">${Title(title, eyebrow, true, true)}</div>
	</section>`
}
