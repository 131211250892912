import styles from './Text.module.css'
import { PageDocumentDataBodyTextSlice } from '../@types/types.generated'
import { asHTML } from '@prismicio/helpers'
import clsx from 'clsx'
import { Title } from './Title'
import linkResolver from '../lib/linkResolver'

export const Text = (props: PageDocumentDataBodyTextSlice, noTopBorder = false): string => {
	const {
		primary: { eyebrow, title, copy, layout, background },
		items
	} = props

	return /* HTML */ `<section
		class="${clsx(
			styles.Main,
			layout === 'left' && styles.Left,
			background === 'grey' && styles.Grey,
			background === 'dark-grey' && styles.DarkGrey,
			noTopBorder && styles.NoTopBorder
		)}"
		data-background="light"
	>
		<div class="${styles.Inner}">
			<div class="${styles.Sidebar}">${Title(title, eyebrow)}</div>
			<div class="${styles.Content}">
				<div class="${styles.Copy}">${asHTML(copy, linkResolver)}</div>
				${items.length > 0
					? /* HTML */ `
							<div class="${styles.Anchors}">
								${items
									.map(
										(item) =>
											/* HTML */ `<a href="#${item.anchor}" class="${styles.Anchor}"
												>${item.label}</a
											>`
									)
									.join('')}
							</div>
					  `
					: ''}
			</div>
		</div>
	</section>`
}
