import styles from './Footer.module.css'
import { FooterDocument } from '../@types/types.generated'
import { Lang } from '../../const'
import { asHTML } from '@prismicio/helpers'
import { isFilled } from '@prismicio/client'
import linkResolver from '../lib/linkResolver'

export function Footer(props: FooterDocument<Lang>): string {
	const {
		data: { copyright, links }
	} = props

	return /* HTML */ `
		<footer class="${styles.Main}">
			<div class="${styles.Inner}">
				<div class="${styles.Copyright}">${copyright}</div>
				<div class="${styles.Links}">
					${links
						.map(({ link }) =>
							/* HTML */ isFilled.richText(link)
								? `<div class="${styles.Link}">${asHTML(link, linkResolver)}</div>`
								: ''
						)
						.join('')}
				</div>
			</div>
		</footer>
	`
}
