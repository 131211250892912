import styles from './Contact.module.css'
import { PageDocumentDataBodyContactSlice } from '../@types/types.generated'
import { asHTML } from '@prismicio/helpers'
import { Title } from './Title'
import linkResolver from '../lib/linkResolver'

export function Contact(props: PageDocumentDataBodyContactSlice): string {
	const {
		primary: { title, eyebrow, address, email, phone }
	} = props

	return /* HTML */ `<section class="${styles.Main}" data-background="dark">
		<div class="${styles.Inner}">
			<div class="${styles.Title}">${Title(title, eyebrow, true, true)}</div>

			<div class="${styles.Content}">
				<div class="${styles.Address}">${asHTML(address, linkResolver)}</div>
				<div class="${styles.Contact}">
					${email ? `<a class="${styles.Email}" href="mailto:${email}">${email}</a>` : ''}
					${phone ? `<a class="${styles.Phone}"  href="tel:${phone}">${phone}</a>` : ''}
				</div>
			</div>
		</div>
	</section>`
}
