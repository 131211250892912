export enum Lang {
	de = 'de-ch',
	en = 'en-eu'
}

export enum ShortLang {
	'de-ch' = 'de',
	'en-eu' = 'en'
}

export enum LangLabel {
	'en-eu' = 'English Version',
	'de-ch' = 'Deutsche Version'
}

export interface Controller {
	dispose?(): void

	resize?(): void

	show?(animate?: boolean): Promise<void>

	hide?(): Promise<void>

	update?(time: number): void

	load?(): Promise<void>

	scroll?(scrollY: number): void
}
