import { Client } from '@prismicio/client'
import { FooterDocument, NavigationDocument, PageDocument } from '../@types/types.generated'
import { Lang } from '../../const'

export async function getData(
	client: Client,
	ref?: string
): Promise<{
	allPages: PageDocument<Lang>[]
	allNavigations: NavigationDocument<Lang>[]
	allFooters: FooterDocument<Lang>[]
}> {
	const [allPages, allNavigations, allFooters] = await Promise.all([
		client.getAllByType<PageDocument<Lang>>('page', {
			ref,
			lang: '*'
		}),
		client.getAllByType<NavigationDocument<Lang>>('navigation', {
			ref,
			lang: '*'
		}),
		client.getAllByType<FooterDocument<Lang>>('footer', {
			ref,
			lang: '*'
		})
	])

	return {
		allPages,
		allNavigations,
		allFooters
	}
}
