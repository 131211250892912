import { Controller } from './App'

export class AnchorController implements Controller {
	private readonly id: string | undefined

	constructor(private readonly node: HTMLElement) {
		this.onHashChange = this.onHashChange.bind(this)
		this.id = node.dataset.id
		this.node.removeAttribute('id')
		window.addEventListener('hashchange', this.onHashChange)
	}

	resize() {}

	onHashChange() {
		const hash = window.location.hash.replace('#', '')
		if (this.id === hash) this.node.scrollIntoView({ behavior: 'smooth' })
	}
}
